/* Cubic Bezier Transition */
/***
Login page
***/
/* bg color */
.login {
    margin-top: 200px;
    background-color: #364150 !important;
}
  
  .login .logo {
    margin: 0 auto;
    padding: 15px;
    text-align: center; }
  
  .login .content {
    background-color: white;
    -webkit-border-radius: 7px;
    -moz-border-radius: 7px;
    -ms-border-radius: 7px;
    -o-border-radius: 7px;
    border-radius: 7px;
    width: 400px;
    margin: 40px auto 10px auto;
    padding: 30px;
    padding-top: 10px;
    overflow: hidden;
    position: relative; }
  
  .login .content h3 {
    color: #4db3a5;
    text-align: center;
    font-size: 28px;
    font-weight: 400 !important; }
  
  .login .content h4 {
    color: #555; }
  
  .login .content .hint {
    color: #999;
    padding: 0;
    margin: 15px 0 7px 0; }
  
  .login .content .login-form,
  .login .content .forget-form {
    padding: 0px;
    margin: 0px; }
  
  .login .content .form-control {
    border: none;
    background-color: #dde3ec;
    height: 43px;
    color: #8290a3;
    border: 1px solid #dde3ec; }
    .login .content .form-control:focus, .login .content .form-control:active {
      border: 1px solid #c3ccda; }
    .login .content .form-control::-moz-placeholder {
      color: #8290a3;
      opacity: 1; }
    .login .content .form-control:-ms-input-placeholder {
      color: #8290a3; }
    .login .content .form-control::-webkit-input-placeholder {
      color: #8290a3; }
  
  .login .content select.form-control {
    padding-left: 9px;
    padding-right: 9px; }
  
  .login .content .forget-form {
    display: none; }
  
  .login .content .register-form {
    display: none; }
  
  .login .content .form-title {
    font-weight: 300;
    margin-bottom: 25px; }
  
  .login .content .form-actions {
    clear: both;
    border: 0px;
    border-bottom: 1px solid #eee;
    padding: 25px 30px 25px 30px;
    margin-left: -30px;
    margin-right: -30px; }
    .login .content .form-actions > .btn {
      margin-top: -2px; }
  
  .login-options {
    margin-top: 30px;
    margin-bottom: 30px;
    overflow: hidden; }
  
  .login-options h4 {
    float: left;
    font-weight: 600;
    font-size: 15px;
    color: #7d91aa !important; }
  
  .login-options .social-icons {
    float: right;
    padding-top: 3px; }
  
  .login-options .social-icons li a {
    border-radius: 15px 15px 15px 15px !important;
    -moz-border-radius: 15px 15px 15px 15px !important;
    -webkit-border-radius: 15px 15px 15px 15px !important; }
  
  .login .content .form-actions .checkbox {
    margin-left: 0;
    padding-left: 0; }
  
  .login .content .forget-form .form-actions {
    border: 0;
    margin-bottom: 0;
    padding-bottom: 20px; }
  
  .login .content .register-form .form-actions {
    border: 0;
    margin-bottom: 0;
    padding-bottom: 0px; }
  
  .login .content .form-actions .btn {
    margin-top: 1px; }
  
  .login .content .form-actions .btn {
    font-weight: 600;
    padding: 10px 20px !important; }
  
  .login .content .form-actions .btn-default {
    font-weight: 600;
    padding: 10px 25px !important;
    color: #6c7a8d;
    background-color: #ffffff;
    border: none; }
  
  .login .content .form-actions .btn-default:hover {
    background-color: #fafaff;
    color: #45b6af; }
  
  .login .content .forget-password {
    font-size: 14px;
    float: right;
    display: inline-block;
    margin-top: 10px; }
  
  .login .content .check {
    color: #8290a3; }
  
  .login .content .rememberme {
    margin-left: 8px; }
  
  .login .content .create-account {
    margin: 0 -40px -30px -40px;
    padding: 15px 0 17px 0;
    text-align: center;
    background-color: #6c7a8d;
    -webkit-border-radius: 0 0 7px 7px;
    -moz-border-radius: 0 0 7px 7px;
    -ms-border-radius: 0 0 7px 7px;
    -o-border-radius: 0 0 7px 7px;
    border-radius: 0 0 7px 7px; }
    .login .content .create-account > p {
      margin: 0; }
  
  .login .content .create-account p a {
    font-weight: 600;
    font-size: 14px;
    color: #c3cedd; }
  
  .login .content .create-account a {
    display: inline-block;
    margin-top: 5px; }
  
  /* footer copyright */
  .login .copyright {
    text-align: center;
    margin: 0 auto 30px 0;
    padding: 10px;
    color: #7a8ca5;
    font-size: 13px; }
  
  @media (max-width: 440px) {
    /***
    Login page
    ***/
    .login {
        margin-top: 70px;
    }
    .login .logo {
      margin-top: 10px; }
    .login .content {
      width: 350px;
      margin-top: 10px; }
    .login .content h3 {
      font-size: 22px; }
    .forget-password {
      display: inline-block;
      margin-top: 20px; }
    .login-options .social-icons {
      float: left;
      padding-top: 3px; }
    .login .checkbox {
      font-size: 13px; } }
  