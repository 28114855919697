/*
 * Baseline styles
 */
 .wrap-loader-inside-component {
  /* width: 100%;
  height: 100%;
  display: block;
  position: relative; */

  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  background: #ffffff73;
  z-index: 200;
}
.wrap-loader-inside-component .loading {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -16px;
  margin-left: -80px;
}

.wrap-loader-inside-component h2 {
  color: #36c6d3;
  margin: 0;
  font: 14px verdana;
  text-transform: uppercase;
  letter-spacing: .1em;
}

/*
 * Loading Dots
 * Can we use pseudo elements here instead :after?
 */
.wrap-loader-inside-component .loading span {
  display: inline-block;
  vertical-align: middle;
  width: 18px;
  height: 8px;
  margin: .19em;
  background: #007DB6;
  border-radius: .6em;
  -webkit-animation: loading 1s infinite alternate;
          animation: loading 1s infinite alternate;
}

/*
 * Dots Colors
 * Smarter targeting vs nth-of-type?
 */
.wrap-loader-inside-component .loading span:nth-of-type(2) {
  background: #008FB2;
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s;
}
.wrap-loader-inside-component .loading span:nth-of-type(3) {
  background: #009B9E;
  -webkit-animation-delay: 0.4s;
          animation-delay: 0.4s;
}
.wrap-loader-inside-component .loading span:nth-of-type(4) {
  background: #00A77D;
  -webkit-animation-delay: 0.6s;
          animation-delay: 0.6s;
}
.wrap-loader-inside-component .loading span:nth-of-type(5) {
  background: #00B247;
  -webkit-animation-delay: 0.8s;
          animation-delay: 0.8s;
}
.wrap-loader-inside-component .loading span:nth-of-type(6) {
  background: #5AB027;
  -webkit-animation-delay: 1.0s;
          animation-delay: 1.0s;
}
.wrap-loader-inside-component .loading span:nth-of-type(7) {
  background: #A0B61E;
  -webkit-animation-delay: 1.2s;
          animation-delay: 1.2s;
}

/*
 * Animation keyframes
 * Use transition opacity instead of keyframes?
 */
@-webkit-keyframes loading {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes loading {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}