.btn-tile {
    display: block;
    width: 100%;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    color: #d9312a;
    border: 1px solid #e0e0e0;
    border-bottom-width: 2px;
    margin-bottom: 26px;
    height: 200px;
    line-height: 200px;
    position: relative;
}
.btn-tile:hover {
    color: #FFFFFF;
    background: #d9312a;
    text-decoration: none;
}
.btn-tile div:first-child {
    margin-top: 10%;
    font-size: 50px;
    margin-bottom: 6px;
    line-height: normal;
}
.btn-tile div {
    display: block;
    text-align: center;
    line-height: 20px;
    font-size: 18px;
    font-weight: bold;
}
.config {
    position: relative;
    padding: 0px 30px 0px 30px;
}
.config .page-header {
    position: absolute;
    padding: 0px;
    border: 0px;
}
.page-header {
    margin: 0px;
    padding: 0px;
    border: 0px;
    text-shadow: none;
    color: #545454;
    font-size: 18px;
    font-weight: 400;
    margin-top: 10px;
}

.config .tab-right.nav-tabs > li {
    font-weight: 400;
}
.tab-right.nav-tabs > li {
    float: right !important;
    margin-bottom: 0px;
}
.config .tab-right li a.active {
    background: #E26A6A;
    color: #FFFFFF;
}
.imgPreviewModal {
    text-align: center;
    margin: 5px 0px;
    height: 250px;
    border: 1px gray solid;
}

.imgPreview {
    text-align: center;
    margin: 5px 15px;
    height: 450px;
    border: 1px gray solid;
}
.imgPreview-2 {
    display: table;
    text-align: center;
    margin: 5px 15px;
    height: auto;
    width: auto;
    /*border: 1px gray solid;*/
}
.container {
    position: relative;
    width: 100%;
    max-width: 400px;
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.container .btn {
    left: 0;
    background-color: #4379b5;
    cursor:pointer;
    position: absolute;
    color: white;
    font-size: 7px;
    cursor: pointer;
    text-align: center;
    z-index: 2;
}

.modal-image {
    display: flex;
    height: 600px;
    width: 100%;


    /*border: 1px gray solid;*/
}
.flex-body {
    display: flex;
    flex-wrap: wrap;
}
.suffer-input {
    width: 50px;
    float: left;
    position: absolute;
}
.padding-left-45 {
    padding-left: 45px;
}
.padding-left-55 {
    padding-left: 55px;
}
.page-header.navbar.navbar-fixed-top, .page-header.navbar.navbar-static-top{
    z-index: 10 !important;
}
.full-review {
    width: 100%;
    text-align: right;
}
.form-group-time {
    width: 40%;
}
.flex-display {
    display: flex;
}
.suffer-input-time {
    width: 60px;
    float: right;
    position: absolute;
}
.page-header.navbar .page-logo {
    width: 195px !important;
    background-color: #fff;
}

/* layout mobile custom*/

@media (max-width: 767px) {
    .page-content-wrapper .page-content {
        padding: 20px 6px 6px !important;
    }
    .portlet {
        padding: 8px !important;
    }
}

/* mobile report portlet */
@media only screen and (min-width: 600px) {
    .portlet.report > .portlet-title > .caption  {
        margin-right: 6px;
    }
}

@media only screen and (max-width: 600px) {
    .portlet.report > .portlet-title > .caption  {
        width: 100%;
        display: block;
        padding-left: 10px;
        padding-right: 10px;
    }
}

/* mobile modal*/
@media only screen and (max-width: 600px) {
    .modal-dialog.modal-image {
        margin: 0px !important;
    }

    .react-viewer .react-viewer-canvas {
        background: white !important;
    }
}

/*border flash*/

@-webkit-keyframes flash {
    0%   { border: 1px solid #d4d4d4; }
    49% { border: 1px solid #d4d4d4; }
    50% { border: 1px solid #d82a23; }
    99% { border: 1px solid #d82a23; }
    100% { border: 1px solid #d4d4d4; }
}
@-moz-keyframes flash {
    0%   { border: 1px solid #d4d4d4; }
    49% { border: 1px solid #d4d4d4; }
    50% { border: 1px solid #d82a23; }
    99% { border: 1px solid #d82a23; }
    100% { border: 1px solid #d4d4d4; }
}
@keyframes flash {
    0%   { border: 1px solid #d4d4d4; }
    49% { border: 1px solid #d4d4d4; }
    50% { border: 1px solid #d82a23; }
    99% { border: 1px solid #d82a23; }
    100% { border: 1px solid #d4d4d4; }
}

.border-flash {
    -webkit-animation: flash 1s infinite;
    -moz-animation:    flash 1s infinite;
    animation:         flash 1s infinite;
}

.btn-add-formula-2 {
    position: absolute;
    top: -65px;
    right: 100px;
}

.btn-add-formula {
    position: absolute;
    top: -65px;
    right: 0;
}

